function parseBoolEnv(envVar) {
  switch (envVar?.toLowerCase()) {
    case 'false':
    case 'no':
      return false
    default:
      return Boolean(Number(envVar))
  }
}

const isDev = process.env.NODE_ENV === 'development'

module.exports = { isDev, parseBoolEnv }
