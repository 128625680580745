import { createContext, ReactNode, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const STORAGE_KEY = 'AUTH_STORAGE_KEY'
const DATA_MODEL_VERSION = 1

type StoredAuth = {
  token: string
  version: typeof DATA_MODEL_VERSION
}

function isAuthDataModel(value: any): value is StoredAuth {
  return (
    value && typeof value === 'object' && 'version' in value && value.version === DATA_MODEL_VERSION
  )
}

export function getStoredAuth(): StoredAuth | undefined {
  if (typeof window !== 'undefined' && window.localStorage !== undefined) {
    const rawData = window.localStorage.getItem(STORAGE_KEY)
    if (rawData == null) {
      return undefined
    }
    const data = JSON.parse(rawData)
    if (data == null) {
      return undefined
    } else if (isAuthDataModel(data)) {
      return data
    } else {
      window.localStorage.removeItem(STORAGE_KEY)
    }
  }
  return undefined
}

function storeNewAuth(token: string) {
  const data: StoredAuth = {
    version: DATA_MODEL_VERSION,
    token,
  }
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
}

function clearStoredAuth() {
  window.localStorage.removeItem(STORAGE_KEY)
}

type Props = {
  children?: ReactNode
}

type IAuthContext = {
  authenticated: boolean
  setAuthenticated: (token: string | undefined) => void
}

const initialValue = {
  authenticated: false,
  setAuthenticated: (token: string | undefined) => {},
}

const AuthContext = createContext<IAuthContext>(initialValue)

const AuthProvider = ({ children }: Props) => {
  //Initializing an auth state with false value (unauthenticated)
  const [authenticated, setAuthenticated] = useState(initialValue.authenticated)

  const { push, route } = useRouter()

  useEffect(() => {
    const token = getStoredAuth()
    if (!token) {
      if (route === '/report/new') {
        push('/login')
      }
    } else {
      setAuthenticated(true)
    }
  }, [route, push])

  useEffect(() => {
    const token = getStoredAuth()
    if (token) {
      setAuthenticated(true)
    } else {
      setAuthenticated(false)
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated: (token: string | undefined) => {
          if (token) {
            storeNewAuth(token)
            setAuthenticated(true)
          } else {
            clearStoredAuth()
            setAuthenticated(false)
          }
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
